/* ContactForm.css */
form {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  background-color: #ff4a17;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  
}

form div {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #FFFFFF;
}



input,
select,
textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
  background-color: #f0f8ff;
 color: #333;
}



textarea {
  resize: none;
  height: 100px;
  
}

button {
  padding: 5px 15px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #218838;
}
