/* src/components/ImageSliderWithZoom.css */

.slider-container {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 100%; /* Set a max width for the slider */
  margin: 0 auto; /* Center the slider */
}

.slider {
  display: flex;
  overflow-x: scroll; /* Allow horizontal scrolling */
  scroll-behavior: smooth; /* Smooth scrolling effect */
  width: 100%; /* Full width */
  
  /* Hide scrollbar */
  -ms-overflow-style: none; /* Internet Explorer and Edge */
  scrollbar-width: none; /* Firefox */
}

.slider::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.slide {
  min-width: 600px; /* Adjust to your desired slide width */
  margin-right: 10px; /* Space between slides */
}

.slider-image {
  width: 100%; /* Make image fill the slide */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Optional: rounded corners */
  cursor: pointer; /* Pointer cursor for zoom effect */
}

.slider-button {
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  height: 50px; /* Increased button height */
  width: 50px; /* Increased button width */
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  font-size: 24px; /* Increased font size for arrow */
}

.slider-button.left {
  left: 10px; /* Position left button */
}

.slider-button.right {
  right: 10px; /* Position right button */
}

.zoomed-image-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 20; /* Above other elements */
}

.zoomed-image {
  max-width: 90%; /* Limit zoomed image size */
  max-height: 90%; /* Limit zoomed image size */
  border-radius: 10px; /* Optional: rounded corners */
}
