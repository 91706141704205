.call-us-popup {
  position: fixed;
  left: 12px; // Adjust as needed
  top: 80%; // Center vertically
  transform: translateY(-50%);
  background-color: #0056b3;
  color: white;
  padding: 12px 11px;
  border-radius: 50px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  z-index: 9999;


  &:hover {
    background-color: #0056b3;
  }
}

@keyframes blink {
  0%, 0% {
    opacity: 0;
  }
  0% {
    opacity: 0.5;
  }
}
