.whatsapp-float {
  position: fixed;
  right: 20px;  /* Adjust this value as needed */
  bottom: 20px; /* Place it near the footer */
  background-color: #25D366;
  color: white;
  border-radius: 50px;
  padding: 10px;
  font-size: 45px;
  z-index: 1000; /* Ensures it stays on top */
  box-shadow: 2px 2px 3px #999;
  transition: background-color 0.3s;
}

.whatsapp-float:hover {
  background-color: #128C7E;
  text-decoration: none;
}

.fab {
  font-family: 'Font Awesome 5 Brands';
}
